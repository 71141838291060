input:focus {
  outline: none;
}

.bg {
  height: 100vh;
  position: relative;
}
.login_wrap {
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  min-width: 500px;
}

.logo {
  text-align: center;
  margin-bottom: 30px;
}
.logo img {
  height: 80px;
}
.login_box {
  border: 1px solid #b8b8b8;
  border-radius: 6px;
  padding: 30px;
}

.account_input {
  cursor: pointer;
  display: flex;
  border: 1px solid #dadada;
  padding: 6px;
}

.input_icon {
  width: 50px;
  text-align: center;
  margin-right: 10px;
  color: #dadada;
}

.input_box {
  flex: 1;
  cursor: pointer;
  border: none;
  border-collapse: collapse;
}
.input_box::placeholder {
  color: #dadada;
}
.input_top {
  border-radius: 6px 6px 0 0;
}
.input_bottom {
  border-radius: 0 0 6px 6px;
  /* margin-top: -1px; */
}

.select_login_input {
  border-color: #0a62d0;
}

.select_login_input .input_icon {
  color: black;
}

.fail_text {
  color: red;
  margin-top: 8px;
  font-size: 14px;
}

.auth_wrap {
  margin-top: 10px;
}

.auth_input {
  flex: 1;
}

.loginBtn {
  display: block;
  width: 100%;
  margin-top: 20px;
  background-color: #0a62d0;
  border: none;
  padding: 8px;
  font-size: 20px;
  font-weight: bold;
  color: white;
}
.authBtn {
  display: block;
  margin-left: 6px;
  background-color: #0a62d0;
  border: none;
  color: white;
  width: 115px;
}
.btn_disable {
  opacity: 0.6;
  cursor: not-allowed;
}

.timer_box {
  display: flex;
  width: 100%;
  font-size: 14px;
  margin-top: 6px;
  color: #222;
}
.timer {
  flex: 1;
}
.auth_expire {
  color: red;
}
.resend {
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 3px;
}
.box_shadow {
  box-shadow: 0 5px 12px 5px rgba(68, 68, 68, 0.08);
}

.radius {
  border-radius: 6px;
}

.mail_form {
  display: none;
}

.notice_Box img {
  width: 200px;
  height: 200px;
}
@media screen and (max-width: 600px) {
  .login_wrap {
    width: 90%;
    min-width: 300px;
  }
  .auth_wrap {
    flex-direction: column;
  }
  .authBtn {
    width: 100%;
    margin-left: 0;
    margin-top: 10px;
    padding: 8px;
  }
}
